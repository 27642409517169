.error-template {
  @apply flex flex-col justify-center items-center;
  height: 100vh;
}

@import url("https://fonts.googleapis.com/css2?family=Fahkwang&display=swap");
.error-id {
  @apply text-orange-s100 flex text-center;
  font-family: "Fahkwang", sans-serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 84px;
}

.error-logo {
  @apply flex items-center mx-2;
}

.error-description {
  @apply mb-14 text-center;
}

.error-details {
  @apply text-center my-2;
}

@media screen(tablet) {
  .error-id {
    font-size: 56px;
  }

  .error-template {
    @apply mx-11;
  }
}
