button {
  @apply flex items-center text-center;
}

.button-primary {
  @apply bg-orange text-black rounded-small font-bold py-16 px-24;
  @apply hover:bg-orange-l30;
}

.button-primary,
.button-secondary {
  @apply disabled:bg-medium-grey disabled:text-light-grey;
}

.button-decorated {
  padding: 32px 24.5px 32px 32.5px;
}

.decorated {
  @apply relative flex p-0;
  @apply hover:text-orange-l30;
}

.decorated.light .decorated-title {
  @apply bg-white;
  @apply disabled:text-medium-grey;
}

.decorated:hover > .inner-circle {
  @apply bg-orange-l30;
}

.decorated:hover > .decorated-title {
  @apply text-orange-l30;
}

.decorated::after {
  @apply absolute flex justify-items-center border-2 border-orange rounded-full;
  content: "";
  width: 70px;
  height: 70px;
  top: 50%;
  -webkit-transform: translateX(-32.5px) translateY(-50%);
  transform: translateX(-32.5px) translateY(-50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.decorated:hover::after {
  @apply border-orange-l30;
  width: 80px;
  height: 80px;
  -webkit-transform: translateX(-37.5px) translateY(-50%);
  transform: translateX(-37.5px) translateY(-50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.inner-circle {
  @apply bg-orange rounded-full;
  width: 6px;
  height: 6px;
  margin-right: 18.5px;
}

.decorated-title {
  @apply flex items-center bg-deep-grey font-bold text-orange w-fit z-10;
  height: 37px;
}

.decorated-title.bg-charcoal-wrapper {
  @apply bg-charcoal;
}

svg path {
  @apply stroke-black;
  stroke-width: 7%;
}

.button-secondary {
  @apply bg-none border border-medium-grey rounded-small px-24 py-16;
}

.button-primary svg {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.button-secondary svg {
  @apply fill-orange stroke-orange;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.button-primary:disabled svg path {
  @apply fill-light-grey stroke-light-grey;
}

.button-primary:hover svg,
.button-secondary:hover svg {
  transform: translateX(4px);
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.button-secondary svg path {
  @apply fill-orange stroke-orange;
}
.button-secondary .arrow-icon {
  @apply ml-16;
}

@media screen(tablet) {
  .decorated::after {
    width: 60px;
    height: 60px;
    transform: translateX(-27px) translateY(-50%);
  }

  .decorated:hover::after  {
    width: 70px;
    height: 70px;
    transform: translateX(-32px) translateY(-50%);
  }

  .decorated-title {
    height: 30px;
  }
}
