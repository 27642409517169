.table-item-content {
  @apply my-40;
  grid-row-gap: 8px;
}

.divider {
  @apply border-t border-t-orange-s100 m-0;
}

.table-item .title {
  @apply text-orange-s100 flex items-baseline;
}

.table-item .content {
  @apply justify-center items-center;
}

@media screen(tablet) {
  .table-item-content {
    @apply my-24;
  }
}
