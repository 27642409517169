.company-profile {
  @apply tablet:mx-24 !important;
  height: 100%;
  margin: 0px 70px 100px;
  scroll-margin-top: 75px;
}

.profile-item {
  @apply tablet:mt-24 !important;
  @apply my-6 w-full;
}

.profile-title {
  width: 180px;
}

.profile-content {
  min-width: 220px;
}

@media screen(tablet) {
  .profile-title {
    width: 760px;
    overflow: hidden;
    margin-bottom: 8px;
  }

  .company-profile {
    scroll-margin-top: 100px;
  }
}
