.divider {
  height: 1px;
}
.divider-dark {
  @apply bg-black;
}

.divider-light {
  @apply bg-white;
}
