.section-heading.light {
  @apply text-black;
}

.section-heading.dark {
  @apply text-white;
}

.section-heading .animated-svg {
  @apply relative;
}

.section-heading .animated-svg img {
  @apply absolute;
  transform: translate(0, -20px);
}

.section-name {
  @apply font-brand text-orange-s100 p-0.5;
  max-width: 344px;
  line-height: 50px;
}

.heading-subtitle {
  @apply font-bold;
}
.heading-title {
  margin: 10px 0 40px;
}

.heading-description {
  max-width: 712px;
}

@media screen(tablet) {
  .heading-title {
    @apply mb-16;
  }

  .section-heading .animated-svg {
    width: 148px;
    height: 160px;
  }

  .section-heading .animated-svg img {
    transform: translate(-26px, 0);
  }
}
