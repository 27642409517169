.section-banner {
  @apply grid w-full justify-items-center;
}

.banner-background,
.banner-content {
  grid-column: 1;
  grid-row: 1;
}

.banner-background {
  @apply rounded overflow-hidden w-full;
  height: 24em;
  object-fit: cover;
}

.banner-background img {
  max-width: none;
  height: 100%;
}

.banner-background .title-en {
  @apply absolute font-brand text-orange-s100;
  margin: 20px 38px;
  z-index: 1;
}

.banner-content {
  @apply bg-charcoal items-center rounded p-40;
  width: 95%;
  transform: translateY(8em);
  grid-row-gap: 0;
}

.banner-content > .animated-svg {
  @apply flex justify-center items-center;
}

.banner-content > .animated-svg > img {
  @apply w-full;
}

.banner-content > .text-content > .title {
  @apply text-white;
  white-space: pre-line;
  margin-bottom: 27px;
}

.banner-content > .text-content {
  @apply pl-2.5 mx-0;
  max-width: 523px;
}

.vision-details-button {
  @apply flex justify-end;
}

@media (min-width: 820px) and (max-width: 1200px) {
  .section-banner .grid-span-1-end {
    justify-content: flex-start;
    grid-column: 2 / -1;
  }
}

@media screen(tablet) {
  .banner-content {
    @apply pt-0 px-24 py-40;
  }
  .banner-content > .animated-svg {
    width: 200px;
  }

  .vision-details-button {
    @apply justify-start mt-40;
  }
}
