.contact-form {
  @apply border border-white rounded bg-white text-black tablet:mx-0 !important;
  margin: 0 32.5px 100px;
}

.section-title {
  @apply my-2 ml-9;
}

.section-content {
  padding: 40px 24px 60px;
}

.contact-form-title {
  @apply mb-40 tablet:mb-32;
}

.contact-main-form {
  @apply flex flex-col items-stretch;
  width: 100%;
  max-width: 634px;
}

.button-row {
  @apply flex justify-between;
  margin-top: 12px;
}
