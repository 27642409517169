.input-wrapper {
  @apply relative w-full;
}

.select:focus {
  box-shadow: 0 0 0 4px #ffb33f40;
}

li.select-li {
  @apply w-full list-none text-black py-2.5 px-16 cursor-pointer hover:bg-off-white;
  height: fit-content;
}

ul.select-ul {
  @apply absolute w-full bg-white border border-white;
  top: calc(100% + 3px);
  left: 0;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.select {
  @apply relative flex justify-between items-center p-3.5 w-full;
}

.input-label {
  @apply my-3;
}

.dropdown-input {
  @apply rounded-small;
  height: 52px;
}

.dropdown-arrow {
  @apply absolute;
  top: 50%;
  left: auto;
  right: 16px;
  transform: translateY(-50%);
}
