.contact-us {
  margin-bottom: 100px;
  scroll-margin-top: 70px;
}

@media screen(tablet) {
  .contact-us {
    scroll-margin-top: 100px;
  }
}
