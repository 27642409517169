.about-banner {
  grid-column: 1;
  grid-row: 1;
  @apply overflow-hidden;
  padding-top: 97px;
  height: 24em;
  width: 100vw;
  object-fit: cover;
}

.about-banner .background-img {
  object-position: top -260px left 0px;
  @apply w-full;
}

.catchphrase-section-heading {
  @apply bg-white;
  padding: 70px;
}

.about-section-heading,
.value-section-heading,
.vision-section-heading,
.contact-section-heading {
  @flex;
  padding: 70px;
  row-gap: 70px;
}

.contact-section-heading {
  @apply mb-36;
}

.name-title {
  @apply my-3;
}

.name-description {
  @apply mb-10;
}

.direction-table {
  margin: 70px 40px 0px 334px;
}

@media screen(tablet) {
  .direction-table {
    @apply m-3;
  }

  .about-banner {
    height: 550px;
  }

  .catchphrase-section-heading {
    padding: 70px 24px;
  }

  .catchphrase-section-heading .left-section {
    @apply flex justify-center items-center;
  }

  .catchphrase-section-heading .left-section img {
    margin-left: 24px;
  }
  .about-section-heading,
  .value-section-heading,
  .vision-section-heading {
    @flex;
    padding: 24px;
    margin-bottom: 36px;
  }

  .contact-section-heading {
    @flex;
    padding: 24px;
    margin-bottom: 136px;
  }

  .direction-table {
    margin: 0px;
  }

  .about-banner .background-img {
    @apply w-full h-auto;
    object-position: center;
    object-fit: cover;
  }
}

@media screen(mobile) {
  .about-banner .background-img {
    @apply h-full w-auto;
  }
}
