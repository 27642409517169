.faq-page {
  @apply px-[70px];
  padding-top: 120px;
}

.section-heading-divider {
  margin-top: -20px;
  margin-bottom: 70px;
}

@media screen(tablet) {
  .faq-page {
    padding-top: 100px;
  }
}
