.scroll-button {
  @apply hidden fixed flex-col justify-center items-center rounded-lg bg-orange-s100 text-black opacity-50;
  width: 60px;
  height: 60px;
  left: auto;
  right: 16px;
  bottom: 16px;
  padding: 10px 0;
  row-gap: 8px;
}

.scroll-button.visible {
  @apply flex;
}

.scroll-button.visible:hover,
.scroll-button.visible:active {
  @apply opacity-100;
}

.scroll-button .arrow-icon {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin: 0;
}
