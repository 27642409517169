.collapsible-menu {
  @apply bg-none flex flex-col mb-24;
  row-gap: 8px;
}

.menu-title-area {
  @apply border border-white rounded flex justify-between px-24 py-16;
  transition: all 5s ease;
}

.menu-title-area .title {
  @apply text-left;
}

.arrow-icon {
  @apply bg-none transition-all;
  margin-left: 1rem;
}

.collapsible-menu .arrow-icon.rotated {
  transform: rotate(90deg);
}

.collapsible-menu .arrow-icon svg path {
  @apply stroke-orange;
}

.menu-content-area {
  @apply overflow-y-hidden;
  height: 0;
  transition: height 5s ease-in-out;
}

.menu-content-area.visible {
  height: fit-content;
  transition: height 5s ease-in-out;
}

.menu-content-area p {
  @apply px-24 py-16;
}
