.why-us {
  margin-bottom: 120px;
  margin: 0px 70px 100px;
  scroll-margin-top: 130px;
}

.why-us-table {
  margin-top: 50px;
}

@media screen(tablet) {
  .why-us {
    @apply mx-24 mb-40;
    scroll-margin-top: 100px;
  }

  .why-us .title-section {
    @apply mb-40 grid-cols-1;
    grid-template-areas:
      "title-area"
      "content-area";
  }
}
