.service-details {
  @apply flex flex-col;
  margin: 0 32.5px 100px;
  row-gap: 100px;
  scroll-margin-top: 70px;
}

@media screen(tablet) {
  .service-details {
    margin: 0 0 40px;
    row-gap: 40px;
    scroll-margin-top: 100px;
  }
}
