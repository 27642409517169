.footer {
  @apply flex flex-wrap items-center mr-4 bg-black;
  row-gap: 24px;
  padding: 40px 70px;
  justify-content: space-between;
}

.footer-links {
  @apply flex flex-wrap text-p-small;
  row-gap: 8px;
  column-gap: 16px;
}

.version-number {
  @apply text-light-grey;
}

@media screen(tablet) {
  .footer {
    @apply flex flex-col items-start;
    padding: 40px 24px 24px;
  }

  .footer > .logo > img {
    @apply ml-4 mb-6;
    height: 68px;
  }

  .footer-links {
    @apply flex flex-col items-start;
    justify-content: space-between;
  }

  .footer-link {
    @apply text-p font-semibold;
    margin-top: 16px;
  }

  .version-number {
    margin-top: 48px;
  }
}
