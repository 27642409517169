.vision {
  padding: 0 70px;
  margin-bottom: calc(100px + 8em);
}

@media screen(tablet) {
  .vision {
    @apply tablet:px-8 !important;
    margin-bottom: calc(80px + 8em);
  }
}
