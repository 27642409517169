.header {
  @apply flex fixed w-full text-white items-center justify-between;
  padding: 22px 72px;
  transition: padding ease-in-out 0.3s;
  z-index: 99;
}

.scrolled .header {
  @apply fixed bg-black px-4 py-2;
  transition: padding ease-in-out 0.3s;
}

.scrolled .navigation-links {
  transition: padding ease-in-out 0.3s;
}

.scrolled .header .logo img {
  height: 40px !important;
}

.navigation-links {
  @apply flex text-white list-none justify-center;
  padding-left: 0px;
  transition: padding ease-in-out 0.3s;
}

.navigation-link {
  @apply list-none justify-center items-center;
  margin-right: 26px;
}

a {
  @apply text-white;
  text-decoration: none;
}

@media screen(tablet) {
  .header {
    padding: 22px 24px 12px;
  }

  .scrolled .header {
    @apply fixed bg-black;
    z-index: 99;
    padding: 12px;
  }

  .scrolled .header .logo img {
    height: 40px !important;
  }

  .header .button-main {
    @apply hidden;
  }
  .navbar .navigation-links {
    @apply hidden;
  }
}
