.mobile-menu {
  @apply fixed h-full bg-white hidden tablet:block;
  width: 100vw;
  top: 0;
  left: 100%;
  z-index: 98;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}

.mobile-menu.opened {
  left: 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}

.mobile-menu .button-main {
  @apply w-fit;
}

.mobile-menu .hero-header-animation {
  @apply opacity-50;
  top: 50%;
  right: -12em;
  mix-blend-mode: normal;
}

.mobile-menu-content {
  @apply absolute flex flex-col z-10;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}

.mobile-menu-content .navigation-links {
  @apply flex flex-col items-start;
  row-gap: 16px;
  margin-bottom: 27px;
}

.mobile-menu-content .navigation-link {
  @apply flex text-black cursor-pointer p-3 pr-0;
}

.mobile-menu-content .navigation-link::before {
  @apply inline-block rounded bg-orange-s100;
  content: "";
  width: 8px;
  height: 8px;
  margin-right: 10px;
}