.service-detail-card.dark {
  @apply bg-none;
}

.service-detail-card.light {
  @apply bg-white text-black;
}

.service-detail-card {
  @apply border border-light-grey rounded;
}

.details-title {
  @apply flex items-center;
  padding: 21px 36px;
}

.details-content-wrapper {
  @apply p-40;
}

.details-number {
  @apply font-brand text-orange-s100;
}
.service-title {
  @apply font-bold;
  margin-left: 40px;
}

.case-studies {
  @apply mt-40;
}

.case-studies .left-section {
  @apply tablet:hidden;
}

.left-section .title {
  @apply font-bold mb-1.5;
}

@media screen(tablet) {
  .details-content-wrapper {
    @apply p-0 pb-32;
  }

  .case-studies {
    @apply py-0 px-8 justify-items-center;
  }

  .see-all-button {
    @apply flex justify-center;
  }
}