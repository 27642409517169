.case-study-card {
  @apply w-full rounded p-32 tablet:p-16;
}

.case-study-light {
  @apply border border-black bg-white text-black;
}

.case-study-dark {
  @apply bg-black text-white;
}

.case-number {
  @apply mr-16;
}
.case-title {
  @apply flex items-center mb-32 tablet:mb-16;
}

.case-content {
  @apply flex flex-wrap-reverse mt-32 tablet:mt-16;
  row-gap: 24px;
}
.case-content .case-description {
  @apply mr-8;
  flex: 1;
  min-width: 280px;
}

.case-content .case-info {
  @apply flex flex-col;
  width: 280px;
  row-gap: 16px;
}
.criteria {
  @apply flex;
}
.case-study-card .info {
  width: 85px;
}
.case-study-card .details {
  @apply mb-8;
  flex: 1;
}

.case-number {
  @apply text-orange-s100;
}

.info {
  @apply text-orange-s100 font-bold;
}

.divider {
  @apply border-t border-t-light-grey;
}

@media screen(tablet) {
  .case-content {
    row-gap: 24px;
  }
  .case-content .case-info {
    @apply max-w-none !important;
    width: 100%;
    row-gap: 8px;
  }

  .case-study-card .case-content .case-description {
    min-width: 240px;
  }
}
