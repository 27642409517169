.input-wrapper {
  @apply w-full text-black mb-3;
}

.input-wrapper input,
.input-wrapper textarea {
  @apply w-full;
}

.input-label {
  @apply font-bold my-4;
}

.text-input,
.input {
  @apply rounded-small border border-black px-3 py-4;
  height: 52px;
  width: 635px;
}

.text-input::placeholder,
.input::placeholder {
  @apply text-p-small;
}

.text-input:focus,
.input:focus {
  box-shadow: 0 0 0 4px #ffb33f40;
  outline-style: none;
}

input:disabled,
textarea:disabled,
textarea:read-only,
input:read-only {
  @apply pointer-events-none opacity-60 bg-off-white;
  pointer-events: none;
  opacity: 0.6;
}

.input.error {
  @apply bg-error-background;
  box-shadow: 0px 4px 4px rgba(255, 196, 196, 0.04),
    0px 4px 12px rgba(255, 196, 196, 0.24);
}

.error-message {
  @apply text-error;
  margin: 8px 0 0 16px;
}

.required {
  @apply flex font-bold text-error justify-center items-center ml-2;
}

.input-email > svg path {
  stroke: white;
  stroke-width: 2px;
}

.at-mark {
  @apply absolute;
  transform: translateX(-150%) translateY(115%);
}

.text-input {
  resize: none;
  min-height: 104px;
}
