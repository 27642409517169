.service-outline {
  margin-bottom: 100px;
  scroll-margin-top: 70px;
}

.service-outline .title-section {
  @apply border-y border-y-medium-grey;
}

.service-outline .title-section > .title {
  @apply text-orange-s100;
}

.service-outline .title-section > hr {
  @apply text-medium-grey;
}

.service-outline .content-section {
  margin: 80px 70px 100px;
}

.service-outline .description {
  @apply h-fit tablet:mb-40 !important;
  grid-row-gap: 40px;
  margin-bottom: 80px;
}

.description .content-catchphrase {
  @apply text-orange-s100;
}

.services-list {
  @apply auto-rows-auto py-8;
  grid-row-gap: 16px;
}

@media screen(tablet) {
  .service-outline {
    scroll-margin-top: 100px;
  }

  .service-outline .content-section {
    margin: 24px 24px 40px;
  }

  .service-outline .p-small {
    @apply text-caption;
  }

  .service-outline h4 {
    @apply text-p-large;
  }

  .service-outline .services-list {
    grid-row-gap: 16px;
  }
}
