.service-card {
  @apply flex flex-col justify-between items-center bg-charcoal border border-charcoal;
  border-radius: 10px;
  min-width: 250px;
  padding: 28px 26px;
  row-gap: 30px;
}

.service-description {
  width: 100%;
}

.left-align {
  @apply flex flex-col justify-center items-center mb-1.5;
  row-gap: 30px;
}

.service-card .animated-svg {
  @apply w-full;
}

.service-description > .title {
  @apply text-white;
}

.service-description > .content {
  margin-top: 30px;
}

.item {
  @apply flex flex-col;
  display: flex;
  flex-direction: column;
}

.service-details-btn {
  @apply flex justify-end fill-orange-s100 stroke-orange-s100 w-full;
}

svg path {
  stroke-width: 6%;
}

.animated-svg > img {
  @apply w-full aspect-square;
}

.services-gif {
  @apply w-full;
}

@media screen(tablet) {
  .service-card {
    @apply mt-0;
    row-gap: 16px;
  }
  .service-card .animated-svg {
    max-width: 240px;
  }

  .service-card .left-align {
    row-gap: 0;
  }
}