.mobile-menu-button {
  @apply relative flex flex-col items-end;
  width: 32px;
  height: 21px;
}

.mobile-menu-button span {
  @apply absolute w-full bg-orange-s100 rounded;
  content: "";
  height: 3px;
  top: 9px;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mobile-menu-button span:nth-child(2) {
  width: 20px !important;
}

.mobile-menu-button.opened span:nth-child(2) {
  @apply opacity-0;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mobile-menu-button span:nth-child(1) {
  margin-top: -9px;
  -webkit-transition-property: margin, -webkit-transform;
  transition-property: margin, transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}

.mobile-menu-button.opened span:nth-child(1) {
  @apply opacity-100;
  margin-top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}

.mobile-menu-button span:nth-child(3) {
  margin-top: 9px;
  -webkit-transition-property: margin, -webkit-transform;
  transition-property: margin, transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}

.mobile-menu-button.opened span:nth-child(3) {
  @apply opacity-100;
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
