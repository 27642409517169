@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --grid-column-gap: 24px;
  --grid-column-count: 4;
  --grid-item--min-width: 240px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-column-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );
}

html {
  scroll-behavior: smooth;
}

body {
  @apply font-sans m-0 bg-deep-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a,
button,
button * {
  @apply cursor-pointer;
}

@font-face {
  font-family: "Neuropol";
  src: local("Neuropol"),
    url("./assets/fonts/Neuropol/Neuropol.ttf") format("truetype");
  font-weight: normal;
}

@layer base {
  /* Typography 
  https://www.figma.com/file/VFQjTcfbdT6PMHWIIDxLOe/monotas.net-Design-(%2BCopyWriting%E7%89%88)?node-id=846%3A13245
  */
  h1 {
    @apply font-sans text-h1 font-bold;
  }

  h2 {
    @apply font-sans text-h2 font-bold;
  }

  h3 {
    @apply font-sans text-h3 font-bold;
  }

  h4 {
    @apply font-sans text-h4 font-bold;
  }

  h5 {
    @apply font-sans text-h5 font-bold;
  }

  p {
    @apply font-sans text-p;
  }

  caption {
    @apply font-sans text-caption;
  }

  button {
    @apply border-opacity-0;
  }
}

@layer components {
  .p-large {
    @apply font-sans text-p-large;
  }

  .p-small {
    @apply font-sans text-p-small;
  }

  .monotas-font {
    @apply font-brand;
    line-height: 50px;
  }

  .section-px {
    @apply tablet:px-24 !important;
    padding-left: 70px;
    padding-right: 70px;
  }

  .responsive-grid {
    @apply grid;
    grid-column-gap: var(--grid-column-gap);
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
  }

  .grid-span-1 {
    grid-column: 1 / 2;
  }

  .grid-span-1-end {
    grid-column: 4 / -1;
  }

  .grid-span-2 {
    grid-column: 2 / 4;
  }

  .grid-span-3 {
    grid-column: 2 / -1;
  }

  @media screen(tablet) {
    .responsive-grid {
      --grid-column-gap: 16px;
    }
    .grid-span-1,
    .grid-span-1-end,
    .grid-span-2,
    .grid-span-3 {
      grid-column: 1 / -1;
    }
  }
}
