.hero-header {
  @apply relative overflow-hidden;
  width: 100vw;
  height: 100vh;
}

.hero-header .navbar {
  @apply absolute w-full;
  z-index: 99;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hero-header .banner {
  @apply flex items-center justify-between w-fit h-full;
  margin: 30px auto;
}

.catchphrase {
  @apply flex flex-col justify-center items-center;
  max-width: 624px;
}

.catchphrase-text {
  @apply text-center;
}

.hero-header-animation {
  @apply absolute mix-blend-color-burn opacity-30;
  width: 700px;
  aspect-ratio: 1 / 1;
  top: 50%;
  transform: translateY(-50%);
}

.hero-header-animation:first-child {
  left: -16em;
}

.hero-header-animation:last-child {
  left: auto;
  right: -16em;
}

.yellow {
  @apply text-orange-s100;
}

.down {
  margin-bottom: 36px;
}

@media screen(tablet) {
  .hero-header .navbar {
    @apply fixed;
    top: 0;
  }

  .hero-header .banner .hero-header-animation {
    left: -12em;
  }
}

@media screen(mobile) {
  .hero-header .banner .hero-header-animation:last-child {
    display: none;
  }
}
