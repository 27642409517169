.faq {
  @apply tablet:mx-24 !important;
  height: 100%;
  margin: 0px 70px;
  margin-bottom: 100px;
  scroll-margin-top: 100px;
}

.faq-section {
  @apply tablet:mt-24 !important;
  margin: 0px auto 40px;
}

@media screen(tablet) {
  .faq {
    scroll-margin-top: 100px;
  }
}
